//
// DataTable
//

div.dataTables_wrapper {
    .table td {
        border-width: 1px;
    }

    // Dropdown Menu
    .dropdown-menu {
        margin-top: 0;
        background: $gray-100;

        .dropdown-item:hover, .dropdown-item:focus {
            background: $gray-300;
            color: $gray-900;
        }
    }
}

table#data-table {
    .notes-field {
        max-width: 300px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
}

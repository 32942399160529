//
// Grid
//

.grid {
    display: grid;
    grid-gap: 30px;

    &.grid-min500 { grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); }
}

//
// Footer
//

.footer {
    font-size: 0.75rem;
    color: $gray-lighter;

    a, a:visited {
        color: $gray-lighter;
    }

    a:hover, a:focus {
        color: $gray-darker;
    }
}

//
// Invoices
//

.invoice {
    max-width: 980px;
    padding: 4rem 6rem 2rem 8rem;
    margin: 0 auto;

    // 
    // Logo
    // 
    
    .invoice-logo {
        text-align: center;
        margin-bottom: 4rem;
    }

    // 
    // Header
    // 

    .invoice-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4rem;

        .invoice-recipient {
            .invoice-address__sender {
                font-size: 0.6rem;
                margin-bottom: 1rem;
            }
        
            .invoice-address__recipient {
                // ...
            }
        }

        .invoice-document-data {
            font-size: 0.8rem;
        }
    }

    // 
    // Subject
    // 

    .invoice-subject {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 3rem;
    }

    // 
    // Invoice positions
    // 
    
    table.invoice-positions {
        width: 100%;
        font-size: 0.8rem;
        margin-bottom: 2rem;

        thead, tbody { 
            vertical-align: top;;
        }

        th, td {
            padding: 0.5rem;
            border: 1px solid $gray-600;
        }
    }

    // 
    // Footer
    // 

    .invoice-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 4rem;
        padding-top:1rem;
        border-top: 1px solid $gray-600;
        font-size: 0.6rem;

        td {
            vertical-align: top;
        }
    }

}

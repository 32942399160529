//
// Bootstrap Select
//

.bootstrap-select {

    // Dropdown Menu
    .dropdown-menu {
        box-shadow: rgba(0, 0, 0, 0.2) 0 10px 16px 0;
    }

    // Dropdown Toggle
    > select.mobile-device:focus + .dropdown-toggle, .dropdown-toggle:focus {
        outline: none !important;
    }

    // Dropdown Item
    .dropdown-item {
        //&:hover, &:focus { }
        &:active { color: $white; }

        &.active {
            &:hover, &:focus, &:active { color: $white; }
        }
    }
}

.bootstrap-select .dropdown-menu {
    max-width: 200px;
}
.bootstrap-select .dropdown-menu span.text {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bootstrap-select.btn-group .btn .filter-option {
    text-overflow: ellipsis;
}

.select-invoice-country {
    background: #FFFFFF;
    border: 1px dashed $gray-400;
}
/**
 * Variables
 */

$menu-width: 75px;
$menu-background: #40495E;
$menu-color: #B9BFCD; // #FFFFFF;
$menu-link-color: #B9BFCD; //#E2E8F6;

$sub-menu-width: 220px;
$sub-menu-padding-left: 1.5rem;
$sub-menu-padding-right: $sub-menu-padding-left;
$sub-menu-background: #353C4D;
$sub-menu-font-size: 0.75rem;
$sub-menu-title-size: 1rem;

/**
 * Main Navigation
 */

#main-nav {
    position: fixed;
    top: 0; left: 0; bottom: 0;
    z-index: 500;

    display: flex;
    color: $menu-color;

    a { color: $menu-link-color; }

    // Main Menu
    .main-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: $menu-width;
        background: $menu-background;

        // Icons
        .bi {
            width: 1.5em;
            height: 1.5em;
        }

        // Menu Item
        .menu-item {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: $menu-width;
            border-left: 3px solid transparent;

            &:hover, &.active {
                background: $sub-menu-background;
                border-left: 3px solid $secondary;
            }

        }

        // Image
        img {
            width: 50px;
            height: 50px;
        }
    }

    // Sub Menu
    .sub-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: $sub-menu-width;
        background: $sub-menu-background;
        font-size: $sub-menu-font-size;

        .menu-item {
            display: flex;
            align-items: center;

            width: 100%;
            padding: 0.5rem 0 0.5rem 0;

            &:hover, &.active { color: #FFFFFF; }

            // Menu Item Button
            &.btn {
                justify-content: center;
                padding: 10px;
                font-size: 0.75rem;
                color: $white;
            }

            .bi {
                width: 1.5em;
                height: 1.5em;
                margin-right: 0.5rem;
            }
        }

        .menu-header { }

        .menu-bar {
            display:none;
            margin-top: 25vH;
            padding-left: $sub-menu-padding-left;
            padding-right: $sub-menu-padding-right;

            &.active { display: block; }
        }

        .menu-footer {
            margin-top: auto;
        }

        .menu-title {
            font-size: $sub-menu-title-size;
            font-weight: bold;
        }
    }
}

//
// Loading
//

[v-cloak] {
    display: none;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: $white;

    .spinner {
        height: 40px;
        width: 40px;
        animation: rotation 0.8s infinite linear;
        border-left: 4px solid rgba(53,60,77,.15);
        border-right: 4px solid rgba(53,60,77,.15);
        border-bottom: 4px solid rgba(53,60,77,.15);
        border-top: 4px solid rgba(53,60,77,.8);
        border-radius: 100%;
    }
}

@keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}

.after-loading-show {
    display: none;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.flex-justify-content-center {
    display: flex;
    justify-content: center;
}

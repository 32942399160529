//
// General
//

label,
input[type='checkbox'],
.form-check {
    cursor: pointer;
}

// Form Group
.form-group {

    // Alerts
    .alert {
        padding: 0.25rem 1rem;
        margin: 0;
    }
}

// Form Control
.form-control {
    background: $input-bg;

    &:focus {
        background: $input-bg; // $input-focus-bg;
    }
}

// Input Group
.input-group-append {
    .disabled { background: $input-disabled-bg; }
}

//
// Auth Form
//

.auth-form {
    color: $gray-lighter;

    .form-control {
        border: none;
        margin: 0;
        padding: 0;
        background: none;

        &:focus {
            background: none;
        }
    }

    ::placeholder {
        color: $gray-lighter;
        opacity: 1;
    }

    a {
        color: $gray-lighter;
    }
}

//
// Icon Form
//

.icon-form {
    button {
        margin: 0;
        padding: 0;
        border: 0;
        background: none;
    }
}

//
// Layout
//

$main-nav-width: 295px;

.content {
    min-height: 100vh;
    padding-left: $main-nav-width;
    width: 100%;
}

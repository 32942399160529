//
// Box
//

$_box-spacer: 1.5rem;

.box {
    background: $white;

    // Header
    .box-header {
        padding: $_box-spacer;
    }

    // Tabs
    .box-tabs {
        border-bottom: 1px solid $gray-200;
        padding: 0 $_box-spacer;

        .nav-link {
            margin: 0 $_box-spacer;
            padding: $_box-spacer 0;
            border-bottom: 3px solid transparent;

            &.active { border-bottom-color: $secondary; }
        }
    }

    // Content
    .box-content {
        padding: $_box-spacer;
    }

    // .box-content-full { }

    //
    // Typography
    //

    .box-title {
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }

    //
    // Styles
    //

    &.box-info {
        background-color: lighten($info, 30%);
        border: 1px solid $info;
    }

    &.box-success {
        background-color: lighten($success, 40%);
        border: 1px solid $success;
    }

    &.box-danger {
        background-color: lighten($danger, 40%);
        border: 1px solid $danger;
    }

    &.box-warning {
        background-color: lighten($warning, 30%);
        border: 1px solid $warning;
    }
}

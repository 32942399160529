//
// Buttons
//

button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    text-transform: none;
    padding: 0;
    border: none;
    outline: none;
    color: $link-color;
    text-decoration: $link-decoration;
    background: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:hover, button:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
}

.btn {
    &.btn-reset-input {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        &:hover {
            color: red;
        }
    }
}
//
// Selectpicker
//

.bootstrap-select {
    &.form-control-flex {
        width: 220px !important; // random number?
        flex-grow: 1 !important;
    }
}

//
// Backgrounds
//

.bg-cover {
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

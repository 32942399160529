//
// Wizard
//

$wizard-step-icon-size: 2.5rem;
$wizard-step-arrow-size: 2rem;

$wizard-hover-color: $secondary;
$wizard-done-color: $secondary;
$wizard-current-color: $secondary;
$wizard-pending-color: $gray-300;

.wizard {
    background: $white;

    // Wizard Nav
    .wizard-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.5rem;
        border-bottom: 1px solid $gray-200;
    }

    // Wizard Step
    .wizard-step {
        display: flex;
        align-items: center;
        flex: 1;
        color: $wizard-pending-color;

        // States
        &.done {
            color: $wizard-done-color;
        }

        &.current {
            color: $wizard-current-color;
        }

        &.pending {

        }

        .wizard-step-label {
            flex: 1;
            text-align: center;
            margin-left: 3rem;
            margin-right: 3rem;
            color: inherit;

            //&:hover, &:focus {
            //    color: $wizard-hover-color;
            //}
        }

        // Wizard Step Icon
        .wizard-step-icon {
            margin-bottom: 1rem;

            svg { width: $wizard-step-icon-size; height: $wizard-step-icon-size; }
        }

        // Wizard Step Title
        .wizard-step-title {
            font-size: 0.9rem;
            margin-bottom: 0;
        }

        // Wizard Arrow
        .wizard-arrow {
            svg { width: $wizard-step-arrow-size; height: $wizard-step-arrow-size; }
        }
    }

    // Wizard Content
    .wizard-content {
        padding: 1.5rem;
    }

}

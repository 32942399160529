//
// Icons
//

.icon-hover:hover {
    filter: brightness(50%);
    cursor: pointer;
}

.icon-remove {
    color: $danger;
}

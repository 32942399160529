html {
    scroll-behavior: smooth;
}

html,
body {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin: 0;
}

body {  
    overscroll-behavior: none;
}

hr {
    border-width: 1px;
}

:focus { outline: none !important; }

// 
// Fonts
//

/* poppins-200 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: url('storage/fonts/poppins-v19-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
            url('storage/fonts/poppins-v19-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('storage/fonts/poppins-v19-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
            url('storage/fonts/poppins-v19-latin-200.woff') format('woff'), /* Modern Browsers */
            url('storage/fonts/poppins-v19-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
            url('storage/fonts/poppins-v19-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('storage/fonts/poppins-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
            url('storage/fonts/poppins-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('storage/fonts/poppins-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('storage/fonts/poppins-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
            url('storage/fonts/poppins-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('storage/fonts/poppins-v19-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('storage/fonts/poppins-v19-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
            url('storage/fonts/poppins-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('storage/fonts/poppins-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('storage/fonts/poppins-v19-latin-700.woff') format('woff'), /* Modern Browsers */
            url('storage/fonts/poppins-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('storage/fonts/poppins-v19-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}